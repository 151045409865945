import './aboutUs.css';


const About = () => {

    return (
        <div id='about' style={{ display: 'flex', flexDirection: 'column' }}>
            <div id='mainDiv' style={{ width: '100%', display: 'flex', flex: '1', flexDirection: (window.innerWidth < 700) ? 'column' : 'row' }}>
                <div style={{ width: '100%', flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="/Who we are.png" alt="who we are" />
                </div>
                <div style={{ width: '100%', flex: '2',display:'flex', justifyContent: 'center', alignItems: 'center', padding: (window.innerWidth < 700) ? '1rem' : '2rem' }}>
                    <p className='txt' style={{ flex: '1', fontSize: (window.innerWidth < 700) ? '1.5rem' : '2rem', textAlign: 'left' }}>
                        <b style={{ fontSize: '200%', color: '#7b328a' }}>VOLTERRA HOST</b> company is a combination of multiple majors that merged their expertise to support the global trend toward technology. <b style={{color: '#68c7e4'}}>"Connect the world here"</b> is not just a fleeting motto, Volterra team’s main goal is combining different majors and competencies to achieve customers' needs. Volterra Host was created and organized by specialists who believe in aiming high and maximizing the benefit of using technological approaches to help customers manage their businesses.
                        <br />Servicing customers' requests was the beginning, creating products that support different types of management, financial control, and all that a company needs. However, we are now implementing our own services based on society's needs taking into consideration the magnitudes, sizes, and different obligations of the companies, and noticing that not all businesses know what a combination of programming, electronics, and communications can offer.
                        <br />Our programming team is experts in software engineering, planning, identifying customer needs, building efficient databases, and dealing with different programming languages and platforms (that differ in benefits between different kinds of applications).
                        <br />The electronics team has the requirements to build high-quality environments that support trending needs using innovative technologies. The team is characterized by cleverness in availing global markets' products, and originating new ideas and products.
                        <br />Finally, the communications team is dedicated to installing quality telecommunication solutions with excellent craftsmanship. Our main aim is to build a world where all individuals have the right to communicate through the provision of communication solutions and ongoing support services.</p>
                </div>
            </div>
            {/* <div style={{ textAlign: 'right', clear: 'left', flex: '1', width: '90vw', marginBottom: '5%' }}>
                <button className="btnn">more</button>
            </div> */}
        </div>
    )
}
export default About;
