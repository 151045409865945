import Carousel from 'react-bootstrap/Carousel'
import './carousel.css';


function Carousell() {
  return (
    <div id='carousel' >
      <Carousel fade pause="false" interval={5000} style={{marginTop:window.innerHeight *0.1}}>
        <Carousel.Item>
          <img
            src="/images/programming3.jpg"
            alt="One"
            // style={{ height: '100%', width: '100%' }}
          />
          {/* <Carousel.Caption>
            <h3>Label for first slide</h3>
            <p>Sample Text for Image One</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            src="/images/electronic.jpg"
            alt="Two"
            // style={{ height: '100%', width: '100%' }}
          />
          {/* <Carousel.Caption>
            <h3>Label for second slide</h3>
            <p>Sample Text for Image Two</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            src="/images/telecommunication.jpg"
            alt="Two"
            // style={{ height: '100%', width: '100%' }}
          />
          {/* <Carousel.Caption>
            <h3>Label for second slide</h3>
            <p>Sample Text for Image Two</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
    </div >

  );
}

export default Carousell;
