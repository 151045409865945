import './footer.css'
import { Link } from 'react-scroll'

const Footer = () => {
    const offsets = -window.innerHeight / 10;

    return (
        <div style={{ backgroundColor: '#1D1637', width: '100vw', height: '10%', display: 'flex', flexDirection: 'column', padding: '2rem' }}>
            <img src="/colored-logo.png" alt="logo" style={{ width: (window.innerWidth > 1000) ? '20%' : '50%', alignSelf: 'center', flex: '1' }} />
            <div style={{ display: 'flex', flexDirection: 'row', flex: '1', alignSelf: 'center', alignItems: 'center' }}>
                {/* <a href="https://www.linkedin.com" target={'_blank'} rel="noreferrer" style={{ margin: '20px' }}>
                    <img src="/images/linkedin.png" alt="facebook" style={{ width: '35px', height: '35px' }} />
                </a> */}
                <a href="https://www.facebook.com/Volterrahost" target={'_blank'} rel="noreferrer" style={{ margin: '20px' }}>
                    <img src="/images/facebooklogo.png" alt="facebook" style={{ width: '45px', height: '45px' }} />
                </a>
                <a href="mailto:volterrahost@gmail.com" target={'_blank'} rel="noreferrer" style={{ margin: '20px' }}>
                    <img src="/images/message.png" alt="facebook" style={{ width: '45px', height: '45px' }} />
                </a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flex: '1', alignSelf: 'center', alignItems: 'center' }}>
                <Link className='links' to='carousel' offset={offsets} spy={true} smooth={true} style={{ marginRight: '10px', marginLeft: '10px' }}>HOME</Link>
                <p className='links'>|</p>
                <Link className='links' to='about' offset={offsets} spy={true} smooth={true} style={{ marginRight: '10px', marginLeft: '10px' }}>ABOUT</Link>
                <p className='links'>|</p>
                {/* <Link className='links' to='services' offset={offsets} spy={true} smooth={true} style={{ marginRight: '10px', marginLeft: '10px' }}>SERVICES</Link>
                <p className='links'>|</p> */}
                <Link className='links' to='contact' offset={offsets} spy={true} smooth={true} style={{ marginRight: '10px', marginLeft: '10px' }}>CONTACT</Link>
            </div>
            <p className='copyright'>&copy; Volterra Host 2022 </p>
        </div>
    )
}

export default Footer;