import './App.css';
import Header from './components/header/header';
import Carousel from './components/carousel/carousel';
import About from './components/aboutUs/aboutUs';
import ContactUs from './components/contactUs/contactUs.tsx';
import Footer from './components/footer/footer';

function App() {
  
  return (
    <div className="App" style={{overflowX:'hidden'}}>
      <Header />
      <Carousel />
      <About />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
