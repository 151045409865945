import './header.scss';
import { Link } from 'react-scroll'
import React, { useEffect } from "react";
import * as Scroll from "react-scroll";

const Header = () => {

    let scrollSpy = Scroll.scrollSpy;
    useEffect(() => {
        scrollSpy.update();
    });
    const offsets = -window.innerHeight / 10;

    return (
        <div id='header' className="gr-color row">
            <div className='f-left column'>
                <img className='image' src='/white-logo.png' alt='logo' />
            </div>
            <div className='column div-right'>
                <Link to='carousel' activeClass="active" offset={offsets} spy={true} smooth={true} className='btnlink'>Home</Link>
                <Link to='about' activeClass="active" offset={offsets} spy={true} smooth={true} className='btnlink'>About</Link>
                {/* <Link to='services' activeClass="active" offset={offsets} spy={true} smooth={true} className='btnlink'>Services</Link> */}
                <Link to='contact' activeClass="active" offset={offsets} spy={true} smooth={true} className='btnlink'>Contact</Link>
            </div>
        </div>
    )
}


export default Header;