import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';
import './tailwind.css';

type FormState = {
  email: string;
  name: string;
  phone: string;
  message: string;
};

type ServiceMessage = {
  class: string;
  text: string;
};
function ContactUs() {
  const formId = "MsIgVyAu";
  const formSparkUrl = `https://submit-form.com/${formId}`;
  const recaptchaKey = '6LcaMKEhAAAAAF0rBeOFVgRvRWDDBqHCNjhHVqPQ';
  const recaptchaRef = useRef<any>();

  const initialFormState = {
    email: "",
    name: "",
    phone: "",
    message: "",
  };

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<ServiceMessage>();
  const [recaptchaToken, setReCaptchaToken] = useState<string>();

  const submitForm = async (event: FormEvent) => {
    event.preventDefault();
    setSubmitting(true);
    await postSubmission();
    setSubmitting(false);
  };

  const postSubmission = async () => {
    const payload = {
      ...formState,
      "g-recaptcha-response": recaptchaToken,
    };

    try {
      const result = await axios.post(formSparkUrl, payload);
      console.log(result);
      setMessage({
        class: "bg-green-500",
        text: "Thanks, someone will be in touch shortly.",
      });
      setFormState(initialFormState);
      recaptchaRef.current.reset();
    } catch (error) {
      console.log(error);
      setMessage({
        class: "bg-red-500",
        text: "Sorry, there was a problem. Please try again or contact support.",
      });
    }
  };

  const updateFormControl = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    setFormState(updatedFormState);
  };

  const updateRecaptchaToken = (token: string | null) => {
    setReCaptchaToken(token as string);
  };

  return (
    <div id='contact' style={{width: '100%', justifyContent: 'center', flexDirection: 'column', minHeight: '800px', backgroundImage: 'url(/images/backgroundcontactUs.jpg)', padding: '1%' }}>
      <div className="shadow-2xl" style={{ width: '35%', padding: '5%', minWidth: '350px', margin: 'auto', minHeight: '750px', marginTop: '2%', borderRadius: '5%', background: 'white' }}>
        <h1 style={{ color: '#1D1637', fontSize: '1.5rem', fontWeight: '700', display: 'flex', alignItems: 'center' }}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="transform rotate-45"
              style={{ marginLeft: '1rem', height: '3rem' }}
              viewBox="0 0 20 20"
              fill="#1D1637"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
          </span>
          <span>Contact us</span>
        </h1>
        {message && (
          <div className={`my-4 text-white w-full p-4 ${message.class}`} style={{ borderRadius: '10px' }}>
            {message.text}
          </div>
        )}
        <form onSubmit={submitForm} className="flex flex-col" style={{ width: '100%', height: '100%' }}>
          <div className="my-2 flex flex-col">
            <label className="flex" htmlFor="name" style={{ color: '#1D1637', marginLeft: '1%' }}>Name</label>
            <input
              onChange={updateFormControl}
              className="border-2 p-2"
              type="text"
              id="name"
              style={{ width: '100%', borderRadius: '10px' }}
              value={formState?.name}
            />
          </div>
          <div className="my-2 flex flex-col">
            <label className="flex" htmlFor="email" style={{ color: '#1D1637', marginLeft: '1%' }}>Email</label>
            <input
              onChange={updateFormControl}
              className="border-2 p-2"
              type="email"
              style={{ borderRadius: '10px' }}
              id="email"
              value={formState?.email}
            />
          </div>
          <div className="my-2 flex flex-col">
            <label className="flex" htmlFor="phone" style={{ color: '#1D1637', marginLeft: '1%' }}>Phone</label>
            <input
              onChange={updateFormControl}
              className="border-2 p-2"
              type="text"
              id="phone"
              style={{ width: '100%', borderRadius: '10px' }}
              value={formState?.phone}
            />
          </div>
          <div className="my-2 flex flex-col">
            <label className="flex" htmlFor="message" style={{ color: '#1D1637', marginLeft: '1%' }}>Message</label>
            <textarea
              onChange={updateFormControl}
              className="border-2 p-2 flex"
              id="message"
              style={{ borderRadius: '10px', resize: 'none' }}
              value={formState?.message}
            ></textarea>
          </div>

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaKey}
            onChange={updateRecaptchaToken}
          />

          <button
            disabled={submitting}
            style={{ borderRadius: '10px', width: '40%', alignSelf: 'center', background: 'linear-gradient(to left, #B84794, #5ec2d6 )' }}
            className="mt-4 my-2 bg-blue-700 text-white w-full p-2 hover:bg-blue-900 transition-colors duration-200">
            {submitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
